body {
    font-family: Arial, Helvetica, 'Noto Sans TC', sans-serif;
}

#svg-logo {
    width: 100px;
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    user-select: none;
    transition: all 0.5s ease-in-out;
}

.wrapper.is-dark {
    color: white;
    background-color: black;
}
.wrapper.is-light {
    color: black;
    background-color: white;
}

header {
    padding: 2em;
    padding-bottom: 0;
}

main {
    padding: 0 2em;
    position: relative;
}

footer {
    padding: 2em;
    padding-top: 0;
}

footer .cht {
    font-weight: 500;
    letter-spacing: 0.1em;
}

footer .eng {
    font-weight: 700;
    letter-spacing: 0.02em;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-grow {
    flex-grow: 1;
}

.align-start {
    align-items: flex-start;
}

.justify-space-between {
    justify-content: space-between;
}

.proj-code {
    font-family: 'Arial Black', Arial, Helvetica, sans-serif;
    position: absolute;
    top: -0.75em;
    right: 0.5em;
    font-size: 15vmin;
    line-height: 1em;
    font-weight: 900;
    -webkit-text-stroke-width: 1px;
    transition: all 0.5s ease-in-out;
}

.is-dark .proj-code {
    color: black;
    -webkit-text-stroke-color: white;
}

.is-light .proj-code {
    color: white;
    -webkit-text-stroke-color: black;
}

.title {
    text-transform: uppercase;
    font-size: 2.5em;
    line-height: 1.25;
    font-weight: 900;
    margin-top: -0.25em;
    margin-bottom: 0.5em;
}

.logo {
    width: 6em;
}

.shaker-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    background-image: url(./gird.svg);
    background-size: 12vmin 12vmin;
    background-position: center center;
}

.shaker {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border: 2px solid ;
    transition: all 0.5s ease-in-out;
}

.is-dark .shaker {
    border-color: white;
}

.is-light .shaker {
    border-color: black;
}

.subtitle p {
    margin-top: 1em;
    margin-bottom: 0;
}

.label-box {
    padding: 1em;
    border: 1px solid;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
    width: max-content;
}

.is-dark .label-box {
    background-color:  black;
    border-color: white;
}

.is-light .label-box {
    background-color:  white;
    border-color: black;
}

.label-box p {
    margin: 0;
    font-size: 1em;
}

.label-box p:first-child {
    font-weight: bold;
    font-size: 2.25em;
}

.notice {
    text-align: center;
    padding: 6em 2em;
    font-size: 1.25em;
}

.notice button {
    font-size: 1.5rem;
    color: white;
    background: #000;
}